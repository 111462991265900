// extracted by mini-css-extract-plugin
export var footerAddress = "Footer-module--footerAddress--11c61";
export var footerContact = "Footer-module--footerContact--e3140";
export var footerMobileCopyright = "Footer-module--footerMobileCopyright--86a8e";
export var footerMobileLinks = "Footer-module--footerMobileLinks--1fd80";
export var mobileFooter = "Footer-module--mobileFooter--57dd8";
export var sectionFooter = "Footer-module--section-footer--ce71e";
export var sectionFooterAddress = "Footer-module--section-footer-address--b3512";
export var sectionFooterCol = "Footer-module--section-footer-col--90e93";
export var sectionFooterColTitle = "Footer-module--section-footer-col-title--f4bd2";
export var sectionFooterContent = "Footer-module--section-footer-content--262a9";
export var sectionFooterContentLeft = "Footer-module--section-footer-content-left--8dc1c";
export var sectionFooterContentRight = "Footer-module--section-footer-content-right--349c8";
export var sectionFooterCopyright = "Footer-module--section-footer-copyright--1a73a";
export var sectionFooterCopyrightText = "Footer-module--section-footer-copyright-text--f362b";
export var sectionFooterPhone = "Footer-module--section-footer-phone--8eefc";
export var sectionFooterPremierLogo = "Footer-module--section-footer-premier-logo--1c730";
export var sectionFooterSocials = "Footer-module--section-footer-socials--d4c54";